import { graphql } from 'gatsby';
import React from 'react';
import { ContactSection } from '../components/ContactSection';
import { Hero, HeroImageFragment, SrcMap } from '../components/Hero';
import { Layout } from '../components/Layout';
import { ContentfulRichText } from '../components/RichText';
import { Section } from '../components/Section';
import { ServiceCard } from '../components/ServiceCard';
import { urls } from '../constants';
import { styled } from '../styles/styled';

interface Props {
  data: {
    page: {
      contentTitle: string;
      contentSubtitle: string;
      heroImage: {
        title: string;
        headline: string;
        backgroundImage: HeroImageFragment;
      };
      services: Array<{
        id: string;
        shortTitle: string;
        slug: string;
        shortDescription: {
          shortDescription: string;
        };
      }>;
    };
  };
}

const Cards = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  grid-gap: ${props => props.theme.spacing(2)};
  @media (min-width: ${props => props.theme.bp.lg}) {
    grid-template-columns: repeat(2, minmax(400px, 25%));
  }
`;

export default (props: Props) => {
  const {
    contentTitle,
    contentSubtitle,
    services,
    heroImage: { backgroundImage, title, headline },
  } = props.data.page;
  const imageMap: SrcMap = {
    sm: backgroundImage.sm.src,
    md: backgroundImage.md.src,
    lg: backgroundImage.lg.src,
    xl: backgroundImage.xl.src,
  };
  return (
    <Layout>
      <Hero full title={title} subtitle={headline} imageSrc={imageMap} />
      <Section alt title={contentTitle} subTitle={contentSubtitle}>
        <Cards>
          {services.map(service => (
            <ServiceCard
              key={service.id}
              to={service.slug}
              title={service.shortTitle}
            >
              <ContentfulRichText
                node={
                  service.shortDescription
                    ? JSON.parse(service.shortDescription.shortDescription)
                    : {}
                }
              />
            </ServiceCard>
          ))}
        </Cards>
      </Section>
      <Section
        title="Want to talk?"
        subTitle="Contact us today to discuss your specific needs and objectives"
      >
        <ContactSection />
      </Section>
    </Layout>
  );
};

export const query = graphql`
  query HomePageQuery {
    page: contentfulHomePage {
      contentTitle
      contentSubtitle
      heroImage {
        title
        headline
        backgroundImage {
          ...HeroImage
        }
      }
      services {
        id
        shortTitle
        slug
        shortDescription {
          shortDescription
        }
      }
    }
  }
`;
