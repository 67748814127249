import Button from '@material-ui/core/Button';
import { Link } from 'gatsby';
import React from 'react';
import { styled } from '../styles/styled';
import { Divider } from './Divider';

interface Props {
  title: React.ReactNode;
  to: string;
  children?: React.ReactNode;
}

const Root = styled.article`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: ${props => props.theme.mainLight};
  color: #fff;
  padding: ${props => props.theme.spacing(6)};
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
`;

const Title = styled.h4`
  font-weight: 400;
  color: #fff;
  margin: 0;
`;

const Body = styled.main`
  font-size: 0.9rem;
  flex: 1;
  margin-bottom: ${props => props.theme.spacing(2)};
`;

const MoreLink = styled(Link)`
  font-weight: 400;
  font-size: 14px;
  display: inline-block;
  padding: 0.625em 1.6em;
  color: ${props => props.theme.main};
  border: 1px solid #fff;
  border-radius: 60px;
  text-decoration: none;
  background-color: #fff;
  text-transform: uppercase;
  &:hover {
    text-decoration: none;
    background-color: #ececec;
    color: ${props => props.theme.mainLight};
  }
`;

const Footer = styled.footer`
  padding-top: 16px;
`;

const ServiceCard: React.SFC<Props> = ({ title, children, to }) => (
  <Root>
    <header>
      <Title>{title}</Title>
    </header>
    <Divider align="left" variant="light" />
    <Body>{children}</Body>
    <Footer>
      <Button color="default" component={Link} to={to} variant="contained">
        Learn More
      </Button>
    </Footer>
  </Root>
);

export { ServiceCard };
